.Heading_XL_highlights {
	font-family: 'Source Sans Pro';
	font-weight: 600;
	font-size: 40px;
	line-height: 48px;
	color: #000000;
}

.Heading_XL {
	font-family: 'Source Sans Pro';
	font-weight: 300;
	font-size: 40px;
	line-height: 48px;
	color: #000000;
}

.Heading_L {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 40px;
	color: #000000;
}
.Heading_H {
	color: #020617;
	/* WP Desktop/Heading 3 */
	font-family: "Libre Franklin";
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.Heading_M_highlights {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
}

.Heading_M {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	color: #000000;
}

.Heading_S_highlights {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
}

.Heading_S {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
}

.Body_L {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
}

.Body_L_highlights {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 32px;
	color: #000000;
}

.Body_M_highlights {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}

.Body_M {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}

.Body_S_highlights {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

.Body_S {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

.Body_XS_highlights {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	color: #000000;
}

.Body_XS {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #000000;
}

@media screen and (max-width: 765px) {
	.M_Heading_XL_highlights {
		font-family: 'Source Sans Pro';
		font-weight: 600;
		font-size: 40px;
		line-height: 48px;
		color: #000000;
	}

	.M_Heading_XL {
		font-family: 'Source Sans Pro';
		font-weight: 300;
		font-size: 40px;
		line-height: 48px;
		color: #000000;
	}

	.M_Heading_L {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 300;
		font-size: 32px;
		line-height: 40px;
		color: #000000;
	}

	.M_Heading_M_highlights {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
	}

	.M_Heading_M {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		color: #000000;
	}

	.M_Heading_S_highlights {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
	}

	.M_Heading_S {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		color: #000000;
	}

	.M_Body_L {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		color: #000000;
	}

	.M_Body_L_highlights {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 32px;
		color: #000000;
	}

	.M_Body_M_highlights {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
	}

	.M_Body_M {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
	}

	.M_Body_S_highlights {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
	}

	.M_Body_S {
		font-family: 'Libre Franklin';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
	}

	.M_Body_XS_highlights {
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		color: #000000;
	}

	.M_Body_XS {
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: #000000;
	}
}

.WP_Desktop_Heading_4 {
	font-family: 'Libre Franklin';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.WP_Desktop_Terra_Body_SemiBold {
	font-family: 'Libre Franklin';
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
